import { createApp, defineAsyncComponent } from 'vue';
import { GlobalEvents } from 'vue-global-events';
import VueLazyLoad from 'vue3-lazyload';
import FloatingVue from 'floating-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import AuthorizationWrapper from '@/components/AuthorizationWrapper';
import httpService from '@/api/httpService';
import errorParser from '@/helpers/errorParser';
import { PurchaseStates, Authorizations, Notify } from '@/constants';
import { Logger } from '@/utils/logger';
// Issue with missing functions from vue-demi when importing from "element-plus" only
import ElementPlus from 'element-plus/dist/index.full.js';
import 'element-plus/dist/index.css';
import App from './App.vue';
import router from './router';
import './utils/logger';
import i18n from './plugins/i18n';
import store from './store';
import { initializeAuth } from './plugins/auth';
import initDirectives from './plugins/directives';
import initFontAwesome from './plugins/font-awesome';
import Sentry from './plugins/sentry';
import initVeeValidate from './plugins/vee-validate';
import { registerServiceWorker } from './service-worker-helper';
import initGlobalMixin from './mixins/global';
import AppContext from './AppContext';

const LandingLayout = defineAsyncComponent(() => import('@/layouts/LandingLayout'));
const MerchantsLayout = defineAsyncComponent(() => import('@/layouts/MerchantsLayout'));
const DefaultLayout = defineAsyncComponent(() => import('@/layouts/DefaultLayout'));
const BlankLayout = defineAsyncComponent(() => import('@/layouts/BlankLayout'));

const app = createApp(App);

app.use(i18n);
app.use(VueAxios, axios);

Sentry.initSentry(app, router);

app.config.warnHandler = (warn, vm, info) => {
  // Do not log warnings for tertiary buttons, as they are used for styling purposes only
  if (vm?.nativeType == 'button' && vm?.type == 'tertiary') {
    return;
  }

  Logger.warn({
    id: 'vue-warn-handler',
    warning: JSON.stringify({ info, warn: errorParser(warn) }),
  });
};

app.config.errorHandler = (error, vm, info) => {
  Logger.error({
    id: 'vue-error-handler',
    error: JSON.stringify({ info, error: errorParser(error) }),
  });
};

initGlobalMixin(app);
initVeeValidate(app);
initFontAwesome(app);
initDirectives(app);

registerServiceWorker();

// https://github.com/murongg/vue3-lazyload
app.use(VueLazyLoad, {});

// Register layouts
app.component('layout-default', DefaultLayout);
app.component('layout-blank', BlankLayout);
app.component('layout-landing', LandingLayout);
app.component('layout-merchants', MerchantsLayout);

// v-tooltip: https://floating-vue.starpad.dev/guide/
app.use(FloatingVue);

// Global components
app.component('AuthorizationWrapper', AuthorizationWrapper);
app.component('GlobalEvents', GlobalEvents);

const { http, baseUrl } = httpService.initialize();
app.config.globalProperties.$http = http;
app.config.globalProperties.$baseUrl = baseUrl;

// Set constants as global
app.config.globalProperties.PurchaseStates = PurchaseStates;
app.config.globalProperties.Authorizations = Authorizations;
app.config.globalProperties.Notify = Notify;

AppContext.setGlobalProperties(app.config.globalProperties);

initializeAuth(app, router, http);
app.use(ElementPlus);
app.use(router);
app.use(store);

router.isReady().then(() => app.mount('#pledg-app'));
